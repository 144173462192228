import { CloseOutlined, IconButton } from "@SIAnalytics/ovision-design-system"
import React from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import { DOMAIN_NAME, PATH_HOME, PATH_SIGN_IN_ADD_INFO, PRIVATE_DOMAINS } from "../../../index"
import SearchGlobeImg from "../../asset/images/SearchGlobe.png"
import OvisionSymbolImg from "../../asset/logo/OvisionSymbolLogo.png"
import styles from "./SignInLayout.module.scss"

type Props = {
  isAdminWeb: boolean
}

function SignInLayout(props: Props) {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <div className={styles.signInLayout}>
      <section className={styles.leftPanel}>
        <img className={styles.heroImage} alt={""} src={SearchGlobeImg} />
      </section>
      <section className={styles.outletContainer}>
        <div className={styles.scrollable}>
          <Outlet />
        </div>
        <img className={styles.ovisionLogo} alt={""} src={OvisionSymbolImg} />
      </section>

      {!props.isAdminWeb && location.pathname !== PATH_SIGN_IN_ADD_INFO && !PRIVATE_DOMAINS.includes(DOMAIN_NAME) && (
        <IconButton
          wrapperClassName={styles.closeBtn}
          type={"square"}
          htmlType={"button"}
          icon={<CloseOutlined />}
          onClick={() => navigate(PATH_HOME)}
        />
      )}
    </div>
  )
}
export default SignInLayout
